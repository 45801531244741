<template>
  <div>
    <div>浏览数: {{ viewUserCount || 0 }},沟通用户{{ userArray && userArray.length || 0 }}</div>
    <el-table :data="userArray">
      <el-table-column label="头像" width="150">
        <template slot-scope="scope">
          <el-image
            style="width: 30px; height: 30px"
            :src="scope.row.avatar"
            fit="cover">
            <div slot="error" class="image-slot">
              <el-image
                style="width: 30px; height: 30px"
                fit="cover"
                :src="DEFAULT_AVATAR_PATH"
              ></el-image>
            </div>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column property="username" label="昵称" width="200"></el-table-column>
    </el-table>
  </div>
</template>

<script>
import {user} from "@/apis/index";

export default {
  name: 'DemandAccessUser',
  props: {
    id: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      userArray: [],
      viewUserCount: 0
    }
  },
  watch: {
    id: {
      handler(newValue, oldValue) {
        this.getAccessUser()
      },
      deep: true
    }
  },
  methods: {
    getAccessUser() {
      if (!this.id) {
        return Promise.reject();
      }
      return user.viewContactUsers({
        userId: this.user.userId,
        id: this.id,
        type: this.type,
      }).then(res => {
        const {retdata = [], viewUsers = 0} = res
        this.viewUserCount = viewUsers
        this.userArray = retdata
      })
    }
  },
  computed: {},
  beforeMount() {
    this.getAccessUser()
  }
}
</script>
